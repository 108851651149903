import { gsap } from "gsap";

export default function gsapHero() {

  let titles = document.querySelectorAll('.story--header__title')
  gsap.utils.toArray('.multitalent-wasserstoff .story--header .dot').forEach((dot) => {
    let posX = dot.dataset.x
    let posY = dot.dataset.y

    //randomize starting positions slightly
    let posXRand = posX * Math.random() * 5
    let posYRand = posY * Math.random() * 5

    // fade dots in from corners
    let tl = gsap.timeline()
      .from(dot, {
        x: (posX > 50) ? posXRand  : posXRand * -1,
        y: (posY > 50) ? posYRand  : posYRand * -1,
        duration: 3
      })
  })

  //tween titles
  let tl = gsap.timeline()
    .from(titles[0], {
      x: "-100%",
      y: "-100%",
      duration: 1.5
    })
    .from(titles[1], {
      x: "100%",
      y: "100%",
      duration: 1.5
    },0)
}
