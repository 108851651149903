const breakpoints = {
  'mobile': 320,
  'phablet': 500,
  'tablet': 770,
  'tablet_xl': 860,
  'tablet_ls': 1024,
  'desktop': 1100,
  'wide': 1140,
  'laptop': 1400,
  'super_wide': 1600,
  'nav': 800
}

export {breakpoints}
