import $ from "jquery";
import ScrollMagic from "scrollmagic";

let scrollController = new ScrollMagic.Controller();

/*
  disable reveals on orientation change
 */
window.addEventListener('orientationchange', disableReveal);

function disableReveal() {
  document.querySelector('body').classList.add('is-revealed')
}
function initScrollMagic() {
  var windowWidth = $(window).width();
  if (windowWidth >= 500) {
    $(".reveal").each(function () {
      let $self = $(this);
      let options_scroll = {
        triggerElement: $self[0],
        // triggerHook: .6,
        triggerHook: "onEnter",
        offset: 50,
      };

      new ScrollMagic.Scene(options_scroll)
        .setClassToggle($self[0], "is-active")
        .addTo(scrollController);
    });

    $(".reveal--left").each(function () {
      let $self = $(this);
      let options_scroll = {
        triggerElement: $self[0],
        // triggerHook: .6,
        triggerHook: "onEnter",
        offset: 300,
      };

      new ScrollMagic.Scene(options_scroll)
        .setClassToggle($self[0], "is-active")
        .reverse(false)
        .addTo(scrollController);
    });

    $(".reveal--right").each(function () {
      let $self = $(this);
      let options_scroll = {
        triggerElement: $self[0],
        // triggerHook: .6,
        triggerHook: "onEnter",
        offset: 300,
      };

      new ScrollMagic.Scene(options_scroll)
        .setClassToggle($self[0], "is-active")
        .reverse(false)
        .addTo(scrollController);
    });

    let reveal_check = $(".reveal--image").length;
    if (reveal_check >= 1) {
      $(".reveal--image").each(function () {
        let $self = $(this);
        let options_scroll = {
          triggerElement: $self[0],
          triggerHook: 0.5,
          // triggerHook: 'onEnter',
          offset: 50,
        };
        new ScrollMagic.Scene(options_scroll)
          .setClassToggle($self[0], "svg-start")
          .reverse(false)
          .addTo(scrollController);
      });
    }
  }
}

$(document).ready(function () {

  //init SM
  initScrollMagic()

  // SCROLLIMAGE
  let check = $(".scroll--image-img").length;
  if (check >= 1) {
    let section_height = $(".section--interview").height();
    let img_height = $(".scroll--image-img").height() * 1.25;
    // console.log(section_height, img_height);

    let scroll_height = section_height;
    let controller_image = new ScrollMagic.Controller();
    $(function () {
      // wait for document ready
      // build scene
      let scene = new ScrollMagic.Scene({
        triggerElement: ".scroll--image-img",
        triggerHook: 0.1,
        duration: scroll_height - img_height,
      })
        .setPin(".scroll--image-img")
        .addTo(controller_image);
    });
  }

  // Hide Header
  let hide_check = $(".scroll--hide").length;
  if (hide_check >= 1) {
    let options_scroll2 = {
      triggerElement: '.further-headline',
      triggerHook: 'onEnter',
      offset: -100,
    };
    new ScrollMagic.Scene(options_scroll2)
      .setClassToggle(".scroll--hide", "is-hidden")
      .addTo(scrollController);
  };


  //Header shrink grow on scroll
  let $self = $("#header");
  let options_scroll = {
    triggerElement: $(".section")[0],
    triggerHook: 'onLeave',
    offset: 100,
  };
  new ScrollMagic.Scene(options_scroll)
    .setClassToggle($self[0], "scrolling")
    .addTo(scrollController);

});

