import { gsap } from "gsap";

export default function gsapNetworkExpansion() {
  let container = document.querySelector('.network-expansion')
  let images = container.querySelectorAll('img')
  let tl = gsap.timeline({
    scrollTrigger: {
      // markers: true,
      trigger: '.network-expansion',
      start: "top 75%",
    }
  })

  // tween each image in network-expansion container
  gsap.utils.toArray(images).forEach((img) => {
    tl
      .from(img, {
        opacity: 0,
        scale: .5
      })
  })
}
