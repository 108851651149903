import { gsap } from "gsap";
import {breakpoints} from '../../scripts/variables'


export default function gsapTimeline() {
  const docWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

  gsap.utils.toArray('.timeline__content').forEach((content) => {
    let box = content.parentNode
    let boxLeft = box.classList.contains('timeline__box--left')
    let indent = content.querySelector('.timeline__indent')
    let text = content.querySelector('.timeline__text')
    let img = content.querySelector('.timeline__figure')

    let tl = gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: content,
        start: "top 85%"
      }
    })

    //fade text in from left/right
    let textAnimationFrom = {
      opacity: 0,
      delay: 1,
      x: boxLeft ? '50%' : '-50%'
    }
    let textAnimationTo = {
      // width: '3rem'
      opacity: 1,
    }

    //fade img in opposite direction from text left/right
    let imgAnimationFrom = {
      opacity: 0,
      x: boxLeft ? '-50%' : '50%'
    }
    let imgAnimationTo = {
      opacity: 1,
    }

    let indentAnimationTo = {
      backgroundImage: 'linear-gradient(to right, white 0%, transparent 0%)',
      width: '3rem',
    }
    /*
    // fade indent background color back to white
    let indentFinishAnimation = {
      backgroundImage: 'linear-gradient(to right, white 100%, transparent 100%)',
    }


    // reverse fade for boxLeft and not tablet_ls
    if (boxLeft && docWidth >= breakpoints.tablet_ls) {
      indentFinishAnimation.backgroundImage = 'linear-gradient(to left, white 100%, transparent 100%)'
    }
    */
    tl
      .add('contentStart')

      if(text) {
        tl.from(text, textAnimationFrom)
          .to(text, textAnimationTo)
      }

      if(img) {
        tl.from(img, imgAnimationFrom)
          .to(img, imgAnimationTo)
      }
      tl.add('contentFinish', '+=1')


    //add indent animations before and after content animation
    if (indent) {
      tl.to(indent, indentAnimationTo,'contentStart')
      // tl.to(indent, indentFinishAnimation,'contentFinish')
    }
  })

}
