import lw from 'lottie-web'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export class Lottie {
  constructor(container, options) {
    this.json = container.dataset.src
    this.initLottie(container, options)
  }

  /**
   * scroll based lottie animation
   * @param options: lottie-web and ScrollTrigger parameters
   * @pararm
   * @url https://greensock.com/docs/v3/HelperFunctions
   */
  LottieScrollTrigger(options) {
    let playhead = {frame: 0},
      target = gsap.utils.toArray(options.target)[0],
      speeds = {slow: "+=2000", medium: "+=1000", fast: "+=500"},
      st = {trigger: target, pin: true, start: "top bottom-=100", end: speeds[options.speed] || "+=1000", scrub: 1},
      animation = lw.loadAnimation({
        container: target,
        renderer: options.renderer || "svg",
        loop: false,
        autoplay: false,
        path: options.path
      });
    for (let p in options) { // let users override the ScrollTrigger defaults
      st[p] = options[p];
    }

    animation.addEventListener("DOMLoaded", function () {
      gsap.to(playhead, {
        frame: animation.totalFrames - 1,
        ease: "none",
        onUpdate: () => animation.goToAndStop(playhead.frame, true),
        scrollTrigger: st
      });
    });
  }

  /**
   * Play lottie on load
   * @param options
   * @constructor
   */
  LottieOnLoad(options) {
    let target = gsap.utils.toArray(options.target)[0],
      animation = lw.loadAnimation({
        container: target,
        renderer: options.renderer || "svg",
        loop: options.loop || true,
        autoplay: options.autoplay || true,
        path: options.path
      });

    animation.addEventListener("DOMLoaded", function () {
      animation.play()
    })
  }

  /**
   * Init lottie
   * @param container div containing lottiefile
   * @param options JSON: options
   */
  initLottie(container, options) {
    if (options.onscroll) {
      this.LottieScrollTrigger({
        target: container,
        markers: options.markers || false,
        path: this.json,
        speed: "fast",
        start: options.start || "top top",
        end: options.end || "bottom top",
        pin: options.pin || false,
        pinSpacing: false,
        scrub: 2 // seconds it takes for the playhead to "catch up"
        // you can also add ANY ScrollTrigger values here too, like trigger, start, end, onEnter, onLeave, onUpdate, etc. See https://greensock.com/docs/v3/Plugins/ScrollTrigger
      });
    } else {
      this.LottieOnLoad({
        target: container,
        path: this.json
      })
    }
  }
}

// initialize
document.querySelectorAll('.lottie__container').forEach((container) => {
  let pin = container.querySelector('.lottie__pin')
  let lottie = container.querySelector('.lottie')
  let options = JSON.parse(lottie.dataset.options) || {}

  options.pin = pin
  new Lottie(lottie, options)
})
