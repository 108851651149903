import Parallax from "parallax-js";
import { gsap } from "gsap";
import $ from "jquery";
import ScrollMagic from "scrollmagic";


// https://codyhouse.co/gem/parallax-hero-image
if (document.getElementById("js-parallax-scene") != null) {
  let scene = document.getElementById("js-parallax-scene");
  let parallaxInstance = new Parallax(scene);
  parallaxInstance.friction(0.1, 0.1);
}

// Story Header
let hero = document.getElementById("scroll--fade");
let triggerOffset = 0;
let sceneStart = 50;
let duration = 600;
var requestId = null;



if (hero) {
  // Update wenn alle Bilder geladen sind
  document.addEventListener("DOMContentLoaded", function (event) {
    let hero_bg = hero.getElementsByClassName("hero__bg");
    if (hero_bg.length == 1) {
      let hero_height = hero_bg[0].clientHeight;
      triggerOffset = 0;
      sceneStart = hero_height;
      duration = hero_height;
    }
  });

  var tl = gsap
    .timeline({ paused: true })
    .to(
      ".hero__bg",
      { duration: duration, opacity: 0, scale: 1.5 },
      sceneStart
    );

  // Only update on animation frames
  window.addEventListener("scroll", function () {
    if (!requestId) {
      requestId = requestAnimationFrame(update);
    }
  });

  update();
}

// Set timeline time to scrollTop
function update() {
  tl.time(window.pageYOffset + triggerOffset);
  requestId = null;
}


// Initialize headline switching on scroll
let hide_check = $(".scroll--hide").length;
if (hide_check >= 1) {
  let scrollController = new ScrollMagic.Controller();
  let options_scroll = {
    triggerElement: '.content__wrapper',
    triggerHook: 0,
    offset: 600,
  };
  new ScrollMagic.Scene(options_scroll)
    .setClassToggle('.scroll--hide', "headline-active")
    .addTo(scrollController);
}
