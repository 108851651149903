import $ from "jquery";
// MOBILE

$(document).ready(function ($) {

  /**
   * toggle menu on click, close menu on click outside
   */
  window.addEventListener('click', function(e){
    if (document.querySelector('.header__title').contains(e.target)){
      e.preventDefault();
      $(".header__menu").toggleClass("active");
      $(".header__title").toggleClass("active");
    } else{
      $(".header__menu").removeClass("active");
      $(".header__title").removeClass("active");
    }
  });
});

$(".header__menu__link--mobile").on("click", function (event) {
    event.preventDefault();
    $(".menu").toggleClass("active");
});

$(".menu").each(function () {
    var $self = $(this);
    var trigger = $self.find(".mobile__trigger");

    trigger.on("click", function (event) {
        var $activeCheck = $self.hasClass("mobile--open");
        if ($activeCheck == true) {
            event.preventDefault();
            $(".menu").removeClass("mobile--open");
        } else {
            event.preventDefault();
            $(".menu").removeClass("mobile--open");
            $self.addClass("mobile--open");
        }
    });
});
