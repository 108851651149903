import $ from "jquery";

if ($(".home__button")) {
  $(".home__button").on("click", function (event) {
    if (isIE()) {
      document
        .getElementsByClassName("home")[0]
        .scrollIntoView({ behavior: "smooth", block: "end" });
    } else {
      event.preventDefault();

      let $sections = $(".section");
      let $first_section = $($sections[0]);

      window.scroll({
        top: $first_section.height(),
        left: 0,
        behavior: "smooth",
      });
    }
  });
}

/* Sample function that returns boolean in case the browser is Internet Explorer*/
function isIE() {
  let ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

  return is_ie;
}
