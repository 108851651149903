import $ from "jquery";
import "slick-carousel";
import "slick-slider";

// $(".slider").slick({
//     infinite: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     centerMode: false,
//     dots: false,
//     speed: 5000,
//     fnCanGoPrevTime: function (instance, currentSlide) {

//         const actualSlide = instance.$slides.eq(currentSlide);
//         return 2000
//     },
//     fnCanGoNextTime: function (instance, currentSlide) {

//         console.log("fnCanGoNext called");
//         const actualSlide = instance.$slides.eq(currentSlide);
//         return 2000
//     },

//     responsive: [
//         {
//             breakpoint: 800,
//             settings: {
//                 centerMode: false,
//                 centerPadding: "0px",
//             },
//         },
//     ],
// });

// $('.slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
//     const currentSlideDom = $(slick.$slides.get(currentSlide));
//     const nextSlideDom = $(slick.$slides.get(currentSlide + 1));
//     $(currentSlideDom).find(".slider__item-img--right")[0].classList.add("slider__animation--moveRightToCenter");
//     $(nextSlideDom).find(".slider__item-img--right")[0].classList.add("slider__animation--nextSlide3070IntoCurrent");
//     $(nextSlideDom).find(".slider__item-img--left")[0].classList.add("slider__animation--nextSlide3070IntoCurrent");
// });


$(".js-slider-custom-mobile").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  dots: true,
  speed: 1000,
  adaptiveHeight: true
});


$(".slider-further").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,
    dots: false,
    speed: 1000,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        centerMode: false,
        centerPadding: "0px",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: "0px",
      },
    },
  ],
});

$(".slider-further__corporate").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    dots: false,
    speed: 1000,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
        centerMode: false,
        centerPadding: "0px",
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        centerPadding: "0px",
      },
    },
  ],
});


// $('.slick-next.slick-arrow').on('click', function () {
//     const currentSlideIndex = $('.slider').slick('slickCurrentSlide');
//     const currentSlideDom = $('.slider').slick('getSlick').$slides.get(currentSlideIndex)
//     const nextSlideDom = $('.slider').slick('getSlick').$slides.get(currentSlideIndex + 1)
//
//     $(currentSlideDom).find(".slider__item-img--right")[0].classList.add("slider__animation--moveRightToCenter");
//     // $(nextSlideDom).find(".slider__item-img--left")[0].classList.add("slider__animation--moveRightToCenter");
//     $(nextSlideDom).find(".slider__item-img--left")[0].classList.add("slider__animation--nextSlide3070IntoCurrent");
//     $(nextSlideDom).find(".slider__item-img--right")[0].classList.add("slider__animation--nextSlide3070IntoCurrent");
//
//     console.log("currentSlideDom", currentSlideDom)
//     console.log("nextSlideDom", nextSlideDom)
// });

