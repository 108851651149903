import $ from "jquery";
import Vivus from "vivus";
import ScrollMagic from "scrollmagic";

let scrollController = new ScrollMagic.Controller();

$(".u-vivus").each(function () {
  const $self = $(this);
  const $svg = $self.find("svg");

  const svgAnimation = new Vivus($svg[0], {
    start: "manual",
    type: "sync",
    duration: 70,
  });

  const scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.7,
  });

  var handleEnter = function () {
    scene.off("enter", handleEnter);
    $self.addClass("is-ready");
    return svgAnimation.play();
  };

  return scene.addTo(scrollController).on("enter", handleEnter);
});
