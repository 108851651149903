import { breakpoints } from '../../scripts/variables'

export default class MQ {
  breakpoints

  constructor() {
    this.breakpoints = breakpoints
  }

  from(breakpoint) {
    if (!(breakpoint in this.breakpoints)) return
    let mediaQuery = window.matchMedia(`(min-width: ${this.breakpoints[breakpoint] / 16}em)`)
    return mediaQuery.matches
  }

  until(breakpoint) {
    if (!(breakpoint in this.breakpoints)) return
    let mediaQuery = window.matchMedia(`(max-width: ${(this.breakpoints[breakpoint] / 16) - 0.01}em)`)
    return mediaQuery.matches
  }
}
