import SimpleBar from 'simplebar'
import MQ from '../../_lib/mq/mq'

export default class Tour {
  tour
  tourPane
  skipIntroButtons
  backButton
  focusContent
  scrollbar
  scrollbarEurope
  scrollbarMobile
  desktopModeInitialized
  mq

  constructor() {
    this.tour = document.querySelector('.tour')
    if (!this.tour) return

    this.mq = new MQ()
    this.desktopModeInitialized = null
    this.tourPane = this.tour.querySelector('.tour__pane')
    this.skipIntroButtons = [...this.tour.querySelectorAll('.tour__intro-skip, .tour__intro-tutorial')]
    this.backButton = this.tour.querySelector('.tour__back-button')
    this.focusContent = this.tour.querySelector('.tour__focus-content')

    this.tour.classList.add('tour--loading')

    window.addEventListener("load", () => {
      this.tour.classList.remove('tour--loading')
      this.initButtons()

      if (this.mq.from('tablet_xl')) {
        this.initDesktopMode()
        this.initTourItems()
      } else {
        this.initMobileMode()
      }

      this.initViewportWatcher()
    })
  }

  initTourItems() {
    let itemToggles = [...this.tour.querySelectorAll('.tour__toggle')]
    let tourItems = [...this.tour.querySelectorAll('.tour__item')]

    itemToggles.forEach(itemToggle => {
      itemToggle.addEventListener('click', (e) => {
        let activeItem = e.target.parentNode

        if (activeItem) {
          activeItem.classList.toggle('tour__item--active')
          if (this.mq.from('tablet_xl')) this.focusActiveContent(activeItem)
        }
      })
    })

    for (let i = 0; i < tourItems.length; i++) {
      tourItems[i].setAttribute('data-tour-item', i)
    }
  }

  initButtons() {
    this.skipIntroButtons.forEach(skipButton => {
      skipButton.addEventListener('click', () => {
        this.skipIntro()
      })
    })
    this.backButton.addEventListener('click', () => {
      this.showIntro()
    })
  }

  initDesktopMode() {
    if(this.desktopModeInitialized == false) {
      let tourWallpaper = this.tour.querySelector('.tour__wallpaper')
      let wallpaperImage = this.tour.querySelector('.tour__wallpaper-image').cloneNode(true)
      this.scrollbarMobile.unMount()
      tourWallpaper.innerHTML = ''
      tourWallpaper.appendChild(wallpaperImage)
      this.resetState()
    }

    this.scrollbar = new SimpleBar(this.tourPane, { autoHide: false })
    this.showIntro()
    this.desktopModeInitialized = true
  }

  initMobileMode() {
    if(this.desktopModeInitialized == true) {
      let tourContent = this.tour.querySelector('.tour__content').cloneNode(true)
      this.scrollbar.unMount()
      this.tourPane.innerHTML = ''
      this.tourPane.appendChild(tourContent)
      this.resetState()
    }

    let tourWallpaper = this.tour.querySelector('.tour__wallpaper')
    this.initTourItems()
    this.scrollbarMobile = new SimpleBar(tourWallpaper, { autoHide: false })
    this.desktopModeInitialized = false
  }

  resetState() {
    let activeItems = [...this.tour.querySelectorAll('tour__item--active')]
    activeItems.forEach(activeItem => activeItem.classList.remove('tour__item--active'))

    this.removeFocusContent()

    if (this.tour.classList.contains('tour--intro')) this.tour.classList.remove('tour--intro')
    if (this.tour.classList.contains('tour--focussed')) this.tour.classList.remove('tour--focussed')
  }

  initViewportWatcher() {
    window.addEventListener('resize', this.debounced(200, () => {
      if (this.mq.from('tablet_xl') && !this.desktopModeInitialized) this.initDesktopMode()
      if (this.mq.until('tablet_xl') && this.desktopModeInitialized) this.initMobileMode()
    }))
  }

  debounced(delay, fn) {
    let timerId
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId)
      }
      timerId = setTimeout(() => {
        fn(...args)
        timerId = null
      }, delay)
    }
  }

  showIntro() {
    let scrollElement = this.scrollbar.getScrollElement()

    this.tour.classList.add('tour--intro')

    scrollElement.scroll({
      left: 0,
      behaviour:'smooth'
    })
  }

  skipIntro() {
    let scrollElement = this.scrollbar.getScrollElement()
    let tourContent = this.tour.querySelector('.tour__content')
    let scrollPosition = (tourContent.offsetWidth - this.tourPane.offsetWidth) / 2

    this.tour.classList.remove('tour--intro')

    scrollElement.scroll({
      left: scrollPosition,
      behaviour:'smooth'
    })
  }

  focusActiveContent(activeItem) {
    let activeContent = activeItem.cloneNode(true)
    let isOverflowingItem = activeContent.classList.contains('tour__item--europe')

    this.focusContent.appendChild(activeContent)
    this.tour.classList.add('tour--focussed')

    if (isOverflowingItem) {
      let tourContentEurope = activeContent.querySelector('.tour__item-scroll-pane')
      this.scrollbarEurope = new SimpleBar(tourContentEurope, { autoHide: false })
    }

    activeContent.querySelector('.tour__item-close').addEventListener('click', (e) => {
      let itemID = e.target.parentNode.parentNode.getAttribute('data-tour-item')
      let activeItems = [...this.tour.querySelectorAll(`[data-tour-item="${itemID}"]`)]

      activeItems.forEach(activeItem => activeItem.classList.remove('tour__item--active'))
      if (this.scrollbarEurope !== undefined) this.scrollbarEurope.unMount()
      this.removeFocusContent()
      this.tour.classList.remove('tour--focussed')
    })
  }

  removeFocusContent() {
    let currentFocusContent = this.focusContent.firstChild
    if (currentFocusContent) this.focusContent.removeChild(currentFocusContent)
  }
}
