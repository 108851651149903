import { CountUp } from "countup.js";
import $ from "jquery";
import ScrollMagic from "scrollmagic";

var scrollController = new ScrollMagic.Controller();

$(".countup-time").each(function () {
  var $self = $(this);
  let counter_value = Number($self.html());

  const options_countup = {
    decimalPlaces: 2,
    decimal: ":",
    duration: 2.8,
    separator: '.',
  };

  let cu = new CountUp($self[0], counter_value, options_countup);

  const options_scroll = {
    triggerElement: $self[0],
    triggerHook: "onEnter",
    offset: $self.height(),
  };

  new ScrollMagic.Scene(options_scroll)
    .on("start", function () {
      cu.start();
    })
    .addTo(scrollController);
});

$(".countup-number").each(function () {
  var $self = $(this);
  let counter_value = Number($self.html());

  const options_countup = {
    duration: 2.8,
    separator: '.',
  };

  let cu = new CountUp($self[0], counter_value, options_countup);

  const options_scroll = {
    triggerElement: $self[0],
    triggerHook: "onEnter",
    offset: $self.height(),
  };

  new ScrollMagic.Scene(options_scroll)
    .on("start", function () {
      cu.start();
    })
    .addTo(scrollController);
});
