import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default function gsapFigureCaptionOverlay() {
  gsap.utils.toArray('.figure--caption-overlay').forEach((figure) => {
    let img = figure.querySelector('img')
    let caption = figure.querySelector('figcaption')
    let isLeft = figure.classList.contains('figure--from-left')

    let tl = gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: figure,
        start: "top 50%",
      }
    })

    let imgAnimationFrom = {
      opacity: 0,
      x: isLeft ? '-100%' : '100%',
      duration: 1
    }
    let captionAnimationFrom = {
      opacity: 0,
      x: isLeft ? '-100%' : '100%',
      duration: .75
    }

    tl.from(img, imgAnimationFrom)
      .from(caption, captionAnimationFrom, '-=.5')
  })
}
