import $ from "jquery";

import "/_components/countup/countup.ts";

// dynamic import
if (document.querySelector('.figure--caption-overlay')){
  import ("/_components/figure/figure").then(({default: gsapFigureCaptionOverlay}) => {
    gsapFigureCaptionOverlay()
  })
}

import "/_components/hero/hero.ts";
import "/_components/home/home.ts";
import "/_components/lottie/lottie.ts";
import "/_components/navigation/navigation.ts";
import "/_components/scroll-reveal/scroll-reveal.ts";
import "/_components/slider/slider.ts";

if (document.querySelector('.showcase')){
  import ("./_components/showcase/showcase").then(({default: Showcase}) => {
    new Showcase()
  })
}
if (document.querySelector('.timeline')){
  import ("/_components/timeline/timeline").then(({default: gsapTimeline}) => {
    gsapTimeline()
  })
}
if (document.querySelector('.tour')){
  import ("./_components/tour/tour").then(({default: Tour}) => {
    new Tour()
  })
}
// import "/_components/table/table.ts";

// STORY LOGIC
if (document.querySelector('.vom-meer-zum-main')){
  import ("./_components/pathway/pathway").then(({default: Pathway}) => {
    new Pathway()
  })
}
if (document.querySelector('.multitalent-wasserstoff')){
  import ("./_components/stories/multitalent-wasserstoff/multitalent-wasserstoff").then(({default: gsapHero}) => {
    gsapHero()
  })
}
if (document.querySelector('.was-kostet-die-zukunft')){
  import ("./_components/stories/was-kostet-die-zukunft/network-expansion").then(({default: gsapNetworkExpansion}) => {
    gsapNetworkExpansion()
  })
}

import "/_atoms/animation/animation.ts";

$("a[href='#top']").click(function() {
  $("html, body").animate({ scrollTop: 0 }, "slow");
  return false;
});
