import MQ from '../../_lib/mq/mq'

export default class Showcase {
  showcase
  allSlides
  slideCount: number
  prevButton
  nextButton
  dots
  currentPos: number
  mq

  constructor() {
    this.showcase = document.querySelector('.showcase')

    if (!this.showcase) return

    this.allSlides = [...this.showcase.querySelectorAll('.showcase__slide')]
    this.slideCount = this.allSlides.length
    this.prevButton = this.showcase.querySelector('.showcase__prev')
    this.nextButton = this.showcase.querySelector('.showcase__next')
    this.dots = this.showcase.querySelector('.showcase__dots')
    this.currentPos = 0
    this.mq = new MQ()

    this.initNav()
    window.addEventListener('load', () => { this.initSlides() })
    this.initDots()
    this.initViewportWatcher()
    this.updateUI()
  }

  initNav() {
    this.prevButton.addEventListener('click', () => {
      this.currentPos = (this.currentPos === 0)? this.slideCount - 1 : this.currentPos - 1
      this.updateUI()
    })
    this.nextButton.addEventListener('click', () => {
      this.currentPos = (this.currentPos + 1 === this.slideCount)? 0 : this.currentPos + 1
      this.updateUI()
    })
  }

  initDots() {
    for (let i = 0; i < this.slideCount; i++) {
      let dot = document.createElement('button')
      dot.classList.add('showcase__dot')
      dot.setAttribute('data-slide-number', `${i}`)
      dot.addEventListener('click', (e) => {
        this.currentPos = e.target.getAttribute('data-slide-number')
        this.updateUI()
      })
      this.dots.appendChild(dot)
    }
  }

  initSlides() {
    let slides = this.showcase.querySelector('.showcase__slides')

    if (this.mq.until('tablet_ls')) {
      let slideHeights = []
      this.allSlides.forEach(slide => { slideHeights.push(slide.offsetHeight) });
      let maxHeight = Math.max(...slideHeights)
      slides.setAttribute('style', `height: ${maxHeight}px`)
    } else {
      slides.removeAttribute('style')
    }
  }

  initViewportWatcher() {
    window.addEventListener('resize', () => {
      this.initSlides()
    })
  }

  updateUI() {
    let formerActiveDot = this.showcase.querySelector('.showcase__dot--active')
    let formerFadeOutSlide = this.showcase.querySelector('.showcase__slide--fadeout')
    let formerCurrentSlide = this.showcase.querySelector('.showcase__slide--active')
    let currentSlide = this.allSlides[this.currentPos]
    let currentDot = this.dots.querySelectorAll('.showcase__dot')[this.currentPos]
    let modifier = currentSlide.getAttribute('data-modifier')

    if (formerFadeOutSlide) formerFadeOutSlide.classList.remove('showcase__slide--fadeout')
    if (formerCurrentSlide) {
      formerCurrentSlide.classList.remove('showcase__slide--active')
      formerCurrentSlide.classList.add('showcase__slide--fadeout')
    }
    if (currentSlide) currentSlide.classList.add('showcase__slide--active')
    if (formerActiveDot) formerActiveDot.classList.remove('showcase__dot--active')
    if (currentDot) currentDot.classList.add('showcase__dot--active')

    this.showcase.setAttribute('class', `showcase showcase--${modifier}`)
  }
}
