import { gsap } from "gsap";
import MQ from '../../_lib/mq/mq'

export default class Pathway {
  pathway
  dotStops
  mq

  constructor() {
    this.pathway = document.querySelector('.pathway')

    if (!this.pathway) return

    this.mq = new MQ()

    let startPosition = (this.mq.until('tablet'))? 800 : 200
    this.pathway.setAttribute('data-progress', startPosition)

    this.dotStops = [...document.querySelectorAll('[data-path-stop]')]
    window.addEventListener('load', () => { this.initDotAnimation() })
  }

  initDotAnimation() {
    this.dotStops.forEach((dotStop) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: dotStop,
          start: "center bottom",
          end: "center top",
          scrub: true,
          onEnter: function() {
            let pathway = document.querySelector('.pathway')
            let dot = document.querySelector('.pathway__dot')
            let dotStopPos = dotStop.getBoundingClientRect().top
            let wrapperPos = document.querySelector('.pathway').getBoundingClientRect().top
            let offset = (!!dotStop.querySelector('.line__counter'))? 10 : -80
            let newDotPos = dotStopPos - wrapperPos + offset
            let progress = parseInt(pathway.getAttribute('data-progress'))

            if(newDotPos > progress) {
              dot.setAttribute('style', `top: ${newDotPos}px`)
              pathway.setAttribute('data-progress', String(newDotPos))
            }
          }
        }
      })
    })
  }
}

